
.shopOperation{
    .header{
        line-height: 78px;
        border: 1px solid #D1D2DB;
        text-align: center;
        position: relative;
        /deep/.count-down{
            span{
                color: #FD4446;
                font-size: 18px;
                font-weight: bold;
            }
        }
        .header-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: 30px;
            top: 10px;
            line-height: 58px;
            .user-info {
                display: flex;
                align-items: center;
                .user-avatar {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    border-radius: 50%;
                    border: 1px solid #eee;
                    box-sizing: border-box;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .user-name {
                    margin-left: 6px;
                }
            }
            .exit-btn {
                margin-left: 24px;
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: all .3s;
                .iconfont {
                    font-size: 20px;
                }
                span {
                    margin-left: 6px;
                }
                &:hover {
                    color: #1122D8;
                }
            }
        }
    }
    .content{
        width: 100%;
        height: calc(100vh - 81px);
        background: linear-gradient(0deg, #4481EB, #04BEFE);
        display: flex;
        align-items: center;
        justify-content: center;
        .content-box{
            display: flex;
            /*align-items: center;*/
            width: calc(100vw - 230px);
            height: calc(100vh - 200px);
            .content-left{
                width: 50%;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .content-right{
                width: 50%;
                height: 100%;
                background: #ffffff;
                display: flex;
                flex-direction: column;
                border-radius: 0 20px 20px 0;
                span:nth-child(1){
                    color: #2F95F1;
                    font-size: 48px;
                    text-align: center;
                    padding-top: 13%;
                }
                span:nth-child(2){
                    padding-right: 40px;
                    padding-left: 40px;
                    color: #666666;
                    font-size: 24px;
                    line-height: 146%;
                    padding-top: 7%;
                }
                .ppt-content {
                    margin-top: 50px;
                    padding: 0 85px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .file_list {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-left: 20px;
                        .ppt-file-icon{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            i.iconfont {
                                font-size: 28px;
                                color: #C84724;
                                margin-right: 5px;
                            }
                            span.file-name {
                                padding: 0;
                                margin: 0;
                                font-size: 16px;
                                color: #333333;
                            }
                        }
                        .upload-suc-icon {
                            padding-left: 20px;
                            i.iconfont {
                                font-size: 16px;
                                color: #67C23A;
                            }
                        }
                    }
                }
            }
        }
    }
}

